import { Box, FormControl, Icon, Input, InputBase, Stack, Typography } from "@mui/material";
import { InputFieldProps } from "./InputField.props";
import React, { ChangeEventHandler } from "react";
import { IMaskInput } from "react-imask";

const InputField = ({ icon, iconColor, placeholder, value, onChange, type, emailMaskError, setEmailMaskError}: InputFieldProps) => {

  const onChangeHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (type === "email") {
      checkEmailMask(e.target.value)
      onChange(e.target.value)
    } else {
      onChange(e.target.value)
    }
  }

  const checkEmailMask = (email: string) => {
    if (/.+@.+.[A-Za-z]+$/.test(email)) {
      setEmailMaskError?.(false);
    } else {
      setEmailMaskError?.(true);
    }
  };

  return <Stack
    direction="row"
    spacing={2}
    sx={{
      border: "2px solid #ddd",
      pl: { xs: 2.5, sm: 3 },
      pr: 2,
      borderRadius: "3rem",
      "& .MuiInputBase-input": { color: theme => theme.palette.text.primary },
      pt: { xs: 1, sm: 2.25 },
      pb: { xs: 1, sm: 1.75 },
    }}
  >
    <Icon component={icon} sx={{ fontSize: { xs: "1.25rem", sm: "1.75rem" }, color: iconColor, transform: { xs: "translateY(.2rem)", sm: "translateY(.15rem)" } }} />
    {
      type === "tel" ?
        <FormControl fullWidth sx={{ "& .MuiInput-input": { fontSize: 19 } }} variant="standard">
          <Input
            value={value}
            disableUnderline
            onChange={onChangeHandler}
            name="textmask"
            placeholder={placeholder}
            id="formatted-text-mask-input"
            inputComponent={TextMaskCustom as any}
          />
        </FormControl>
        :
        <Box position="relative" >

          <InputBase
            fullWidth
            placeholder={placeholder}
            value={value}
            onChange={onChangeHandler}
            sx={{ fontSize: { xs: ".8rem", sm: "1.15rem" } }}
          />
          {
            type === "email" && emailMaskError &&
            <Typography fontSize={13} color={theme => theme.palette.error.dark} position="absolute" bottom={-14} left={0}>Некорректный email</Typography>
          }
        </Box>
    }
  </Stack>
}

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const TextMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask="+0000000000000"
        definitions={{
          '#': /[1-14]/,
        }}
        inputRef={ref}
        onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  },
);

export default InputField;
