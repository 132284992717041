import {Box, Container, Grid, Stack, Typography, Button} from '@mui/material'
import {TelegramIcon} from '../../Icon/TelegramIcon'
// @ts-ignore
import styles from "./LastStep.module.css";
import Marquee from 'react-fast-marquee';

const LastStep = () => {
  return (
    <Box className={styles.LastStepBlock} minHeight={860} height={{xs: "100%", md: "calc(100vh - 134px)"}}>
      <Container sx={{pt: "20vh", pb: "15vh"}}>
        <Grid container alignItems="center" ml={0} spacing={{xs: 0, md: 2}}>
          <Grid item xs={12} md={6}>
            <div className={styles.LastStepImageWrapper}>
              <img src="/images/trader-files/last-step.png" alt="last-step" style={{ display: "block", width: "100%" }} />
            </div>
          </Grid>
          <Grid item xs={12} md={6} mt={{xs: 6, sm: 10, md: 0}}>
            <Stack height="100%" justifyContent="center" pl={{xs: 5, sm: 1, md: 7, lg: 15}}>
              <Typography fontSize={{ xs: "1.75rem", md: "3.5rem" }} color="#fff" fontWeight={700}>Последний шаг</Typography>
              <Box display="flex" alignItems="center" mt={{xs: -.3, md: -1}} mb={1}>
                <Box width="64%" borderRadius={7} sx={{backgroundColor: "#434343"}}>
                  <Box width="92%" borderRadius={7} style={{height: 11, overflow: "hidden"}}>
                    <Marquee speed={20} style={{height: 25, marginTop: -11, overflow: "hidden"}}>
                      <img src="/images/trader-files/load.png" alt="last-step" style={{height: 45, marginLeft: "-3px", display: "block" }} />
                    </Marquee>
                  </Box>
                </Box>

                <div style={{color: "#1491d3", marginLeft: 14, marginTop: 1}}>95%</div>
              </Box>
              <Typography color="#fff" maxWidth={450} fontSize={{xs: "1rem", md: "1.4rem"}}>Чтобы получить материалы, кликните по кнопке Telegram ниже</Typography>

              <Button color="info" href='https://t.me/Cicap_files_bot' target='_blank' variant="contained"
               onClick={() => {
                //@ts-ignore
                ym(96414864, 'reachGoal', 'button_file')
              }}  
                sx={{maxWidth: 200, mt: 3, textTransform: "none", px: 14, borderRadius: 6, height: 65}}>
                <TelegramIcon />
                <Typography ml={1} >
                  Telegram
                </Typography>
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Container>

    </Box>
  )
}
export default LastStep
