import {Icon, IconButton } from "@mui/material";
import {SocialIconProps} from "./SocialIcon.props";

const SocialIcon = ({icon, href}: SocialIconProps) => {
  return <IconButton
    href={href}
    target="_blank"
    sx={{
      background: "#b41c18",
      color: "#fff",
      width: {xs: "2rem", sm: "3rem"},
      height: {xs: "2rem", sm: "3rem"},
      "&:hover": {
        background: "#7d1310",
      }
    }}
  >
    <Icon component={icon} sx={{fontSize: {xs: "1rem", sm: "1.5rem"}}} />
  </IconButton>;
}

export default SocialIcon;
