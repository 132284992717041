import {FeedBackFormProps} from "./FeedBackForm.props";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  Snackbar,
  Stack,
  Typography
} from "@mui/material";
import InputField from "../InputField/InputField";
import {UserIcon} from "../../Icon/UserIcon";
import {PhoneIcon} from "../../Icon/PhoneIcon";
import {useEffect, useState} from "react";
// @ts-ignore
import styles from "./FeedBackForm.module.css";
import {EmailIcon} from "../../Icon/EmailIcon";

const FeedBackForm = ({open, onClose, onSend, lastStep}: FeedBackFormProps) => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [agree, setAgree] = useState(true);
  const [showSent, setShowSent] = useState(false);
  const [showValidateError, setShowValidateError] = useState(false);
  const [pending, setPending] = useState(false);
  const [emailMaskError, setEmailMaskError] = useState(false);

  useEffect(() => {
    if(email === "") {
      setEmailMaskError(false)
    }
  }, [email])

  useEffect(() => {
    if(lastStep) {
      setShowSent(false)
    }
  }, [lastStep])
  

  const handleSubmit = () => {
    if ("" !== name && "" !== phone && agree) {
      setPending(true);
      fetch("https://back.cicap.ru/api/v1/front/feed-backs/landing", {
        method: "POST",
        body: JSON.stringify({name, phone: phone, email, agree}),
        headers: {
          "Content-Type": "application/json",
          "X-Cicap-Landing": "Zip",
        }
      })
        .then(data => data.json())
        .then(data => {
          if (data.result) {
            setShowSent(true);
            setName("");
            setPhone("");
            setEmail("");
          }
        })
        .finally(() => {
          setPending(false);
          onSend();
          setShowSent(true)
        });
    } else {
      setShowValidateError(true);
      setPending(false);
    }
  };
  
  return <>
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      sx={{
        ".MuiPaper-root": {
          borderRadius: "2rem",
          overflow: "hidden",
        },
        " .MuiBackdrop-root": {
          backgroundColor: "rgb(222 222 222 / 85%)",
        },
      }}
    >
      <DialogContent sx={{px: {xs: 2.5, sm: 5}, py: 4, position: "relative"}}>
        <button className={styles.CrossButton} onClick={onClose}>×</button>
        <Typography textAlign="center" fontSize={{xs: "1.2rem", sm: "2.3rem"}} fontWeight="600">Скачивайте <br/>
        <span style={{color: "#cb0000"}}>ТОП-13</span> файлов бесплатно</Typography>
        <Box display="flex" justifyContent="center" >

        <Typography textAlign="center" width="93%" mt={{xs: 1, sm: 0}} fontSize={{xs: ".6rem", sm: "1.15rem"}} sx={{color: "#6b6b6b"}}>Заполни форму и получи файлы для увеличения дохода в трейдинге.</Typography>
        </Box>

        <Stack direction="column" spacing={2} sx={{mt: 2}}>
          <InputField
            icon={UserIcon}
            iconColor="#525252"
            placeholder="Вашe имя"
            value={name}
            onChange={setName}
          />

          <InputField
            icon={PhoneIcon}
            iconColor="#525252"
            placeholder="Ваш телефон"
            value={phone}
            onChange={setPhone}
            type="tel"
          />

          <InputField
            icon={EmailIcon}
            iconColor="#525252"
            placeholder="Ваш Email"
            value={email}
            onChange={setEmail}
            type="email"
            emailMaskError={emailMaskError}
            setEmailMaskError={setEmailMaskError}
          />

          <FormControlLabel
            control={<Checkbox sx={{"& .MuiSvgIcon-root": {fontSize: {xs: "21px", sm: "35px"}}}} checked={agree} onChange={() => setAgree(!agree)} />}
            label={
              <Typography fontSize={{xs: ".55rem", sm: "16px"}} maxWidth={390} color="#909090" fontWeight="600">
                Нажимая кнопку отправить вы соглашаетесь на обработку персональных данных
              </Typography>
            }
          />

          <Button
            disabled={pending || (email && emailMaskError) || !agree || "" === name || (("" === phone || phone.length < 12 ) && "" === email)}
            variant="contained"
            disableRipple
            disableFocusRipple
            disableTouchRipple
            disableElevation
            fullWidth
            sx={{
              textTransform: "none",
              fontSize: {xs: ".8rem", sm: "1.2rem"},
              py: {xs: 1.5, sm: 2.5},
            }}
            onClick={handleSubmit}
          >
            {pending ? "Скачиваем..." : "Скачать файлы бесплатно"}
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>

    <Snackbar onClose={() => setShowSent(false)} open={showSent}>
      <Alert onClose={() => setShowSent(false)} severity="success">Перенаправляем на страницу скачивания</Alert>
    </Snackbar>

    <Snackbar onClose={() => setShowValidateError(false)} open={showValidateError} autoHideDuration={6000}>
      <Alert onClose={() => setShowValidateError(false)} severity="success">
        Необходимо заполнить имя, номер телефона и согласиться с обработкой персональных данных.
      </Alert>
    </Snackbar>
  </>
}

export default FeedBackForm;
