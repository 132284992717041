import {Box} from "@mui/material";
import Header from "../Component/Header/Header";
import Footer from "../Component/Footer/Footer";
import Hero from "../Component/Hero/Hero";
import Features from "../Block/Features/Features";
import {useState} from "react";
import LastStep from "../Component/LastStep/LastStep";
import FeedBackForm from "../Component/FeedBackForm/FeedBackForm";

const FinLanding = () => {
  const [lastStep, setLastStep] = useState(false);
  const [formIsOpen, setFormIsOpen] = useState(false);

  return <Box height="100%">
    <Header />

    {
      lastStep ? <LastStep />
        :
        <>
          <Hero setFormIsOpen={setFormIsOpen} />
          <Features setFormIsOpen={setFormIsOpen} />
        </>
    }

    <Footer />

    <FeedBackForm
      open={formIsOpen}
      lastStep={lastStep}
      onClose={() => setFormIsOpen(false)}
      onSend={() => {
        setFormIsOpen(false)
        setLastStep(true)
      }}
    />
  </Box>
}

export default FinLanding;


